<template>
  <div class="photo">
    <div class="btn">
      <label class="btn-orange" for="uploads">
        选择图片
        <input
          type="file"
          id="uploads"
          :value="imgFile"
          style="position: absolute; clip: rect(0 0 0 0)"
          accept="image/png, image/jpeg, image/gif, image/jpg"
          @change="uploadImg($event, 1)"
        />
      </label>
      <button @click="edit">编辑文字</button>
      <button @click="createPicture">生成图片</button>
    </div>

    <!-- 底图区域 -->
    <div class="map-box">
      <div class="map" id="html2canvas" ref="html2canvas">      
        <div class="wrap">
          <img src="@assets/images/base_photo_1.png" />
        </div>
        <div class="inner">
          <img :src="previews.url" :style="previews.img" />
        </div>

        <!-- 编辑文字 -->
        <input v-if="isShowInput" v-drag draggable="false" class="edit_input" type="text" placeholder="写点什么...">
      </div>

      <!-- 底图 -->
      <div class="basemap">
        <div class="info-item">  
          <div class="line">
            <div class="cropper-content">
              <div class="cropper">
                <vueCropper
                  ref="cropper"
                  :img="option.img"
                  :outputSize="option.size"
                  :outputType="option.outputType"
                  :info="true"
                  :full="option.full"
                  :canMove="option.canMove"
                  :canMoveBox="option.canMoveBox"
                  :original="option.original"
                  :autoCrop="option.autoCrop"
                  :autoCropWidth="option.autoCropWidth"
                  :autoCropHeight="option.autoCropHeight"
                  :fixedBox="option.fixedBox"
                  @realTime="realTime"
                  @imgLoad="imgLoad"
                ></vueCropper>
              </div>
            </div>
          </div>
        </div>
      </div>     
    </div>

    <mt-popup v-model="imgVisible" popup-transition="popup-fade" position="center" lockScroll="true">
      <img :src="htmlUrl"/>
    </mt-popup>

  </div>
</template>

<script>
import { VueCropper } from "vue-cropper";
import html2canvas from "html2canvas";
export default {
  components: {
    VueCropper,
  },
  data() {
    return {
      htmlUrl: "",
      headImg: "",
      imgVisible: false,
      isShowInput: false,
      //剪切图片上传
      crap: false,
      previews: {},
      option: {
        img: "",
        outputSize: 1, //剪切后的图片质量（0.1-1）
        full: false, //输出原图比例截图 props名full
        outputType: "png",
        canMove: true,
        original: false,
        canMoveBox: true,
        autoCrop: false,
        autoCropWidth: 100,
        autoCropHeight: 100,
        fixedBox: true,
      },
      fileName: "", //本机文件地址
      imgFile: "",
      uploadImgRelaPath: "", //上传后的图片的地址（不带服务器域名）
    };
  },
  directives:{
    drag(el){
        el.onmousedown = function(e){
          var disx = e.pageX - el.offsetLeft;
          document.onmousemove = function (e){
              el.style.left = e.pageX - disx+'px';
              el.style.top = e.pageY - disx+'px';
          }
          document.onmouseup = function(){
              document.onmousemove = document.onmouseup = null;
          }
      }
    }
  },
  created() {},
  methods: {
    //上传图片（点击上传按钮）
    finish(type) {
      console.log("finish");
      // 输出
      if (type === "blob") {
        this.$refs.cropper.getCropBlob((data) => {
          let img = window.URL.createObjectURL(data);
          this.model = true;
          this.modelSrc = img;
        });
      } else {
        this.$refs.cropper.getCropData((data) => {
          this.model = true;
          this.modelSrc = data;
        });
      }
    },
    // 实时预览函数
    realTime(data) {
      console.log("realTime");
      this.previews = data;
    },
    //选择本地图片
    uploadImg(e, num) {
      console.log("uploadImg");
      var _this = this;
      //上传图片
      var file = e.target.files[0];
      _this.fileName = file.name;
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
        alert("图片类型必须是.gif,jpeg,jpg,png,bmp中的一种");
        return false;
      }
      var reader = new FileReader();
      reader.onload = (e) => {
        let data;
        if (typeof e.target.result === "object") {
          // 把Array Buffer转化为blob 如果是base64不需要
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }
        if (num === 1) {
          _this.option.img = data;
        } else if (num === 2) {
          _this.example2.img = data;
        }
      };
      // 转化为base64
      // reader.readAsDataURL(file)
      // 转化为blob
      reader.readAsArrayBuffer(file);
    },
    imgLoad(msg) {
      console.log("imgLoad");
      console.log(msg);
    },

    // 编辑文字
    edit() {
      let that = this
      that.isShowInput = true
    },

    // 生成图片
    createPicture() {
      this.$dialog.loading.open()
      html2canvas(this.$refs.html2canvas, {
        backgroundColor: null,
        useCORS: true, // 如果截图的内容里有图片,可能会有跨域的情况,加上这个参数,解决文件跨域问题
      }).then((canvas) => {
        let url = canvas.toDataURL("image/png"); // 最终生成图片的 url
        this.htmlUrl = url;
        this.imgVisible = true
        this.$dialog.loading.close()
      });
    },
  },
};
</script>

<style lang="scss" scope>
.photo {
  overflow: hidden;
  .btn {
    width: 100%;
    font-size: .26rem;
    label, button {
      height: 0.6rem;
      width: 1.5rem;
      color: white;
      background: #1989fa;
      border: 1px solid #1989fa;
      border-radius: 0.08rem;
      text-align: center;
      line-height: 0.6rem;
      margin: 0.2rem .1rem;
      display: inline-block;
    }
  }
  .cropper-box-canvas img {
    display: none;
  }
  .cropper-content {
    display: flex;
    display: -webkit-flex;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
    .cropper {
      width: 7.5rem;
      height: 13.2rem;
    }
    .show-preview {
      flex: 1;
      -webkit-flex: 1;
      display: flex;
      display: -webkit-flex;
      justify-content: center;
      -webkit-justify-content: center;
      .preview {
        overflow: hidden;
        border-radius: 50%;
        border: 1px solid #cccccc;
        background: #cccccc;
        margin-left: 40px;
      }
    }
  }
  .cropper-content .show-preview .preview {
    margin-left: 0;
  }
  .map-box {
    position: relative;
    .basemap {
      height: 13.2rem;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 999;
    }
    .edit_input {
      position: absolute;
      z-index: 1000;
      top: 3rem;
      left: 1.5rem;
      color: white;
    }
    .map {
      width: 7.5rem;
      height: 13.2rem;
      position: relative;
      .wrap {
        width: 100%;
        z-index: 9;
        position: absolute;
        top: 0;
        left: 0;
        img {
          width: 7.5rem;
          margin: 0 auto;
          display: block;
        }
      }
      .inner {
        width: 7.5rem;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        img {
          width: 7.5rem;
          margin-top: 50%;
          margin-left: 30%;
        }
      }
    }
  }
  .mint-popup {
    width: 6.5rem;
    height: auto;
    background: transparent;
    img {
      width: 100%;
    }
  }
}
</style>